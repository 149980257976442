import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { FunctionComponent } from "react"
import {
  ContentWrapper,
  Description,
  FileWrapper,
  TextWrapperWrapper,
  Title,
  Wrapper,
} from "../../styles/HeroElement"

// import Enquirebutton from "../buttons/Enquirebutton"

interface VisaSectionProps {
  title?: string
  description?: string
}

const VisaSection: FunctionComponent<VisaSectionProps> = props => {
  const {
    title = "Visa Applications",
    description = "Let us help you with your Visa application. We ensure an efficient, transparent and seamless process in applying for your required Visa.",
  } = props
  const data = useStaticQuery(graphql`
    query VisasQuery {
      Image: file(relativePath: { eq: "passport.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, quality: 10)
        }
      }
    }
  `)
  return (
    <Wrapper>
      <ContentWrapper>
        <TextWrapperWrapper>
          <Title>{title}</Title>
          <Description>{description}</Description>
          {/* <Enquirebutton /> */}
        </TextWrapperWrapper>
        <FileWrapper>
          <GatsbyImage
            style={styles}
            alt="images"
            image={data.Image.childImageSharp.gatsbyImageData}
          />
        </FileWrapper>
      </ContentWrapper>
    </Wrapper>
  )
}

export default VisaSection
const styles = {
  borderRadius: "20px",
}
