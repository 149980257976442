import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { H3 } from "../../styles/TextStyles"

interface VisaListProps {}

const VisaList: FunctionComponent<VisaListProps> = () => {
  return (
    <Wrapper>
      <TextWrapper>
        <Title>Types of South African Visa Applications:</Title>
        <Description>❖ South African Study Visa</Description>
        <Description>❖ South African Medical Visa</Description>
        <Description>❖ South African Critical Skills Visa</Description>
        <Description>❖ South African Work Visa</Description>
        <Description>❖ South African Business Visa</Description>
        <Description>❖ South African Relative Visa</Description>
        <Description>❖ South African Spousal Visa</Description>
        <Description>❖ South African Retirement Visa</Description>
        <Description>❖ South African Permanent Residence Visa</Description>
        <Description>❖ South African Inter-Company Transfer</Description>
        <Description>❖ South African Holiday Visa</Description>
        <Description>❖ Waivers</Description>
        <Description>❖ Appeal Applications</Description>
      </TextWrapper>
    </Wrapper>
  )
}

export default VisaList

const Wrapper = styled.div`
  padding: 40px 0 80px 0;
`
const TextWrapper = styled.div`
  max-width: 820px;
  display: grid;
  margin: 0 auto;
  gap: 20px;
  @media (max-width: 640px) {
    max-width: 300px;
  }
`
const Title = styled(H3)`
  @media (max-width: 640px) {
    font-size: 18px;
    font-weight: 500;
    line-height: 130%;
  }
`
const Description = styled.p`
  font-size: 14px;
  line-height: 180%;
  span {
    font-weight: bold;
  }
`
