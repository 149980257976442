import React, { FunctionComponent } from "react"
import styled from "styled-components"
import EnquireButton from "../components/buttons/Enquirebutton"
// import VisaCard from "../components/cards/VisaCard"
import Layout from "../components/layout/Layout"
import SEO from "../components/layout/SEO"
import Line from "../components/lines/Line"
import VisaList from "../components/sections/VisaList"
// import VisaHero from "../components/sections/VisaHero"
import VisaSection from "../components/sections/VisaSection"

interface VisaProps {
  title: string
  name: string
  content: string
}

const Visa: FunctionComponent<VisaProps> = props => {
  const {
    title = "Visa Application",
    name = "Keywords",
    content = "Education",
  } = props
  return (
    <Layout>
      <SEO title={title} />
      <meta name={name} content={content} />
      <VisaSection />
      <LineWrapper>
        <Line />
      </LineWrapper>
      <VisaList />
      {/* <VisaHero /> */}
      {/* <VisaCard /> */}
      <ButtonWrapper>
        <EnquireButton />
      </ButtonWrapper>
    </Layout>
  )
}

export default Visa

const LineWrapper = styled.div`
  margin: 50px 0 0 0;
  display: grid;
  justify-content: center;
`
const ButtonWrapper = styled.div`
  display: grid;
  justify-content: center;
  padding: 0 0 120px 0;
  @media (max-width: 640px) {
    padding: 10px 0 40px 0;
  }
`
